body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

:root{
  --maxScreen: 1200px;
  --base-black: rgb(40, 40, 40);
}
.header{
  max-width:var(--maxScreen);
  padding: 40px 40px 0 40px;
  color: var(--base-black);

  /* height: 60px; */
  /* font-size: 1rem; */
}
.header h1{
  font-size: 4rem;
}

.equation-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width:var(--maxScreen);
  padding: 0 40px;
}

.equations {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px;

}

.math-equation {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 50px;
  width: 50px;
  border: 1px solid gray;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  color: var(--base-black);
}
.math-equation:hover{
  background: skyblue;
}

.eq-icon{
  position: absolute;
  top: 5px;
  left: 5px;
}
.eq-icon svg path{
  fill: rgb(4, 65, 4);
}
.eq-details-num{
  position: absolute;
  top: 2px;
  left: 2px;

  border-radius: 30px;
  width: 20px;
  height: 20px;
  background: rgb(7, 104, 7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
.top{
  display: flex;
  gap: 4px;
}

.divisor{
  height: 1px;
  width: 100%;
  background: black;
}


.bottom{
  display: flex;
  gap: 4px;
}


.top-header{
  display: flex;
  /* justify-content: space-between; */
  gap: 60px;
}
.modal-equation{
  width: 100px;
  font-size: 28px;
  display: flex;
  gap: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.post-container{
  display: flex;
  justify-content: space-between;
}


.most-accepted{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 65%;
}
.most-accepted-element{
  display: flex;
  justify-content: space-between;
  
}

.most-accepted-element:nth-child(2){
  padding-bottom: 20px;
}
.post-text,.post-title{
  color: rgb(57, 57, 57);
  font-size: 20px;
}

.post-title{
  font-size: 24px;
  letter-spacing: 5px;
}

.posts-container{
  padding: 30px 0;
}
.show-more-posts{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  cursor: pointer;

}
.show-more-text{
  display: flex;
  align-items: center;
}

.dropdown-arrow{
  display: flex;
  align-items: center;

  transition: .3s;
}
.dropdown-arrow.show-more{
  transform: rotateZ(180deg);
  transition: .3s;
}

.all-posts{
  display: none;
}

.all-posts.show-more{
  display: flex;
  flex-direction: column;
}

.submit-new-post{
  padding-top: 20px;
  display: flex;
  justify-content: right;
  cursor: pointer;
}

.filled-equation {
  background: #63B0CD;
  color: white;

}

.filled-equation > .divisor{
  background: white;
}
.equation-tooltip{
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #63B0CD;
  padding: 10px 20px;
  white-space: nowrap;
  border: 1px rgb(28, 28, 28) solid;
  border-radius: 5px;
}
.equation-filter-form{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.filter-SI{
  margin-bottom: 10px;
  width: 20px;
}




@media only screen and (max-width: 768px) {
 
  .header{
    padding: 20px;
    /* height: 60px; */
    /* font-size: 1rem; */
  }
  .header h1{
    font-size: 2rem;
  }

  .header h2{
    font-size: 1rem;
  }
  div#equation-modal{
    width: 75vw !important;
  }
  .top-header{
    flex-direction: column;
  }
  .equation-wrapper{
    padding: 20px;
  }

  .math-equation{
    font-size: 10px;
    width: 30px;
    height: 30px;
  }

  .top{
    display: flex;
    gap: 2px;
  }
  
  .divisor{
    height: 1px;
    width: 100%;
    background: black;
  }
  
  
  .bottom{
    display: flex;
    gap: 2px;
  }
  .top-header{
    gap: 20px;

  }
  .most-accepted{
    width: 100%;
  }
  
  
}